import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   component: () => import("@/layout/index.vue"),
  //   redirect: "/index",
  //   children: [
  //     // 需要layout的页面
  //     {
  //       path: "index",
  //       name: "index",
  //       meta: {
  //         title: "index",
  //       },
  //       component: () => import("@/pages/index.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: () => import("@/pages/index.vue"),
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "index",
        meta: {
          title: "index",
        },
        component: () => import("@/pages/index.vue"),
      },
    ],
  },
  // 列表页
  {
    path: "/list",
    component: () => import("@/pages/list.vue"),
  },
  // 填写资料页面
  {
    path: "/sign",
    component: () => import("@/pages/sign.vue"),
  },
  // loading页
  {
    path: "/loading",
    component: () => import("@/pages/load.vue"),
  },
  // 申请成功页
  {
    path: "/success",
    component: () => import("@/pages/success.vue"),
  },
  {
    path: "/familiarBook",
    component: () => import("@/pages/agreement/familiarBook.vue"),
  },
  {
    path: "/personal",
    component: () => import("@/pages/agreement/personal_yr.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/pages/agreement/privacy.vue"),
  },
  {
    path: "/userService",
    component: () => import("@/pages/agreement/userService.vue"),
  },
  {
    path: "/fast",
    component: () => import("@/pages/fast.vue"),
  },
  // 不需要layout的页面
  // 替代vue2中的'*'通配符路径
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHashHistory(), // history 模式则使用 createWebHistory()
  routes,
});
export default router;
