import { createApp } from "vue";
import "./style.css";
import "amfe-flexible";
import router from "@/router";
import pinia from "@/store";
import App from "./App.vue";
import "vant/lib/index.css";
import "./styles/common.scss";
const app = createApp(App);
app.use(router);
app.use(pinia);
app.mount("#app");
